@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* overflow-x: hidden; */
}

:root {
  --primaryBackground: #212222;
  --secondaryBackground: # 2f3030;
  --primaryColor: #e0a80d;
  --greyDark: #7d8792;
  --headingColor: #fff;
  --headerColor: #1E1E24;
  --white: #fff;
  --black: #000;
  --sliver: #bac8d3;
  overflow-x: hidden;
}

body {
  /* background-color: var(--headingColor); */
  background-color: #F8F7F3;
  color: var(--white);
  font-family: "Poppins", sans-serif;
}

a {
  color: var(--white);
  text-decoration: none;
  transition: 0.5s;
}

.slider {
  height: 90vh !important;
}

a:hover {
  color: var(--primaryColor);
}

p {
  color: var(--sliver);
  font-size: 15px;
  line-height: 30px;
  margin: 20px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800;
}

.container {
  max-width: 70%;
  margin: auto;
}

.flexsb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*---------header-------------*/
header {
  background-color: var(--headerColor);
  line-height: 10vh;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

header a {
  font-family: 'Fredericka the Great', cursive;
  font-size: 20px;
  color: var(--white);
  width: max-content;
  text-transform: uppercase;
}

.logo-style {
  height: 12vh;
  width: auto;
}

.menu {
  display: none;
}

.desktopMenu {
  display: flex;
  flex-direction: row;
  gap: 2vh;
}

.sidebar-overlay {
  display: none;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: max-content;
  top: 80%;
  left: -3vw;
  background-color: var(--headerColor);
  color: #fff;
  padding: 0 30px 0 30px;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .container {
    max-width:90%;
  }

  header a {
    display: block;
    margin: 0 0 0 10px;
    color: white;
    font-size: 24px;
    text-wrap: wrap;
    /* word-spacing: 0; */
  }

  header .menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    outline: none;
  }

  .line {
    fill: none;
    stroke: rgb(243, 213, 99);
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }

  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }

  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }

  /* Animation for Burger Menu */
  .opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }

  .opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
  }

  .opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }

  .sidebar-overlay {
    display: flex;
    position: fixed;
    height: 100%;
    z-index: 500;
  }

  .page-shade {
    width: 100%;
    height: 100vh;
    position: fixed;
    opacity: 0.6;
    background-color: var(--headerColor);
    z-index: 400;
  }

  .desktopMenu {
    display: none;
  }
  
  .headMobileMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .backButton {
    display: flex;
    align-items: center;
  }

  .hideBackButton {
    display: none;
    transition: opacity 0.3s ease-in-out, width 0.3s ease-in-out;
  }  

  .back-menu{ 
    font-family: 'Fredericka the Great', cursive;
    font-size: 24px;
    color: var(--white);
    width: max-content;
    text-decoration: underline;
  }

  .mobileMenu {
    display: flex;
    position: fixed;
    width: 75%;
    height: 100%;
    flex-direction: column;
    top: 0vh;
    left: 0;
    transition: opacity 0.3s ease-in-out, width 0.3s ease-in-out;
    background-color: var(--headerColor)
  }

  .mobileMenu-content {
    opacity: 1;
    color: black;
    padding-left: 10px;
    transition: opacity 0.3s ease-in-out, width 0.3s ease-in-out;
  }

  .mobileMenu-content a {
    width: 100%;
  }

  .sidebar-category {
    opacity: 1;
    background-color: var(--headerColor);
    width: 100%;
    height: 100%;
    right: -75vw;
    top: 10vh;
    padding: 0 0 0 10px;
    position: absolute;
    right: 0px;
    z-index: 600;
    transition: opacity 0.3s ease-in-out, width 0.3s ease-in-out;
  } 

  
  
  .chevron-icon {
    cursor: pointer;
    font-size: 30px;
    fill: white;
    padding-right: 20px;
  }
  
  .icon-left {
    font-size: 25px;
    transform: rotate(180deg);
  }

  .categoryMenu {
    display: flex;
    flex-direction: row;
  }
 
  .opened {
    width: max-content;
    margin-left: auto;
    padding-top: 10px;
  }

  .showMenu {
    display: block;
    position: fixed;
    width: 100%;
    height: auto;
    background-color: var(--headingColor);
    top: 12vh;
    right: 1px;
  }

  .hideMenu {
    width: 0%;
    overflow: hidden;
    opacity: 0;
  }

  button {
    border: none;
    outline: none;
    background-color: var(--primaryColor);
    color: var(--black);
    padding: 15px 25px;
    border-radius: 50px;
    margin-top: 20px;
    cursor: pointer;
    transition: 0.3s;
    opacity: 0.8;
  }
}


@media screen and (max-width: 425px) {
  header a {
    font-size: 20px;
  }
  .mobileMenu-content .chevron-icon {
    font-size: 20px;
  }
} 
/*---------header-------------*/


/* MAIN CSS FOR WEBSITE GRID */

section {
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*---------hero-slider------------*/
/*
.hero {
  position: relative;
  height: 90vh;
  display: flex;
  align-items: start;
}

.hero::after {
  content: "test";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../public/images/home-bg.png"); 
  background-size: cover;
  background-attachment: fixed;
  z-index: -1;
  width: 100%;
  height: 90vh;
}

.heroContent {
  max-width: 50%;
  margin: auto;
  text-align: center;
}

.hero h1 {
  font-size: 60px;
  font-weight: 600;
  color: var(--primaryColor);
}

*/
/*---------hero-slider-------------*/

/*---------hero-slider-------------*/
.hero-banner {
  width: 100%;
  text-align: center;
}

.hero-banner img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: contain;
}

/*---------hero-slider-------------*/

.heading {
  font-size: 30px;
  color: var(--primaryColor);
  font-weight: 600;
  text-align: center;
  margin-bottom: 60px;
}

/*---------about-------------*/
.about {
  padding-top: 15px;
}

.about .primaryBtn {
  margin-left: 20px;
}

.about .heading {
  text-align: left;
  margin: 0;
}

.left {
  width: 50%;
}

.right {
  width: 50%;
}

/*---------about-------------*/
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

/*---------services-------------*/
.services .box {
  background-color: var(--primaryBackground);
  padding: 35px;
}

.services i {
  color: var(--primaryColor);
}

.services h3 {
  margin-top: 20px;
}

/*---------services-------------*/
.grid4 {
  grid-template-columns: repeat(4, 1fr);
  height: 50vh;
  place-items: center;
  text-align: center;
}

/*---------counter-------------*/
.counter {
  margin-top: 80px;
  position: relative;
}

.hero.counter::after {
  /* background-image: url("../public/images/some-facts-bg.png"); */
  height: 50vh;
}

.counter h1 {
  margin: 0;
}

/*---------counter-------------*/
/*---------contact-------------*/
/* .contact {
  padding-top: 75px;
  margin: 20px 0;
}

.contact .right,
.contact .left {
  padding: 50px;
}

.contact .flex input:nth-child(1) {
  margin-right: 10px;
}

.contact button,
.contact textarea,
.contact input {
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  margin: 10px 0;
  border-radius: 5px;
} */

/*---------contact-------------*/
/*---------footer-------------*/
footer {
  text-align: center;
  background-color: var(--primaryBackground);
  padding: 30px;
  margin-top: 50px;
}

footer i {
  margin: 5px;
  color: var(--primaryColor);
}

/*---------footer-------------*/
/* Kalau Screen di bawah 768px baru berlaku */
@media screen and (max-width: 768px) {
  .slick-hero {
    height: 30vh;
  }

  .slider {
    height: 50vh !important;
  }

  .heroContent {
    max-width: 80%;
  }

  .hero::after {
    /* background-image: url("../public/images/home-bg.png");  */
    background-size: cover;
    object-fit: cover;
    width: 100%;
    height: 60vh;
  }

  /* 
  section {
    height: auto;
  } */

  .contact .flexsb,
  .about .flex {
    flex-direction: column;
  }

  .right,
  .left {
    width: 100%;
  }

  .grid4,
  .grid3 {
    grid-template-columns: repeat(2, 1fr);
  }

  article {
    margin: 0;
    padding-bottom: 50px;
  }
}


 