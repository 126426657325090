/*---------product intro-------------*/
.product-intro {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  max-width: 400px;
  overflow: hidden;
}

/*---------product card-------------*/
.product-list {
  /* display: grid;
  padding-top: 50px;
  justify-content: center; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 75px;
}

.shop-category {
  width: 30%;
  padding: 0 20px 20px 20px;
}

.shop-category a {
  font-size: 18px;
  font-weight: 500;
  color: var(--black);
}

.currCategory {
  color: black;
  padding-bottom: 30px;
}

.currCategory h2 {
  font-size: 30px;
  font-weight: 600;
  font-family: 'Fredericka the Great', cursive;
  color: black;
  margin-bottom: 0px;
}

.descCategory{
  background-color: black;
  max-width: 100%;
  font-family: 'Bellota Text', sans-serif;
  color: white;
  text-align: justify;
  background-color: black;
  padding: 15px;
}

.navCategory {
  display: flex;
  flex-direction: column;
  line-height: 40px;
}

.shop-product {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  padding-top: 50px;
}

.content-product {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.even {
  flex-direction: row-reverse;
 }

.odd {}

.button-show {
  background: linear-gradient(to bottom, #ffec64 5%, #e0a80d 100%);
  background-color: #8a7d1a;
  border-radius: 16px;
  border: 1px solid #ffaa22;
  display: inline-block;
  cursor: pointer;
  color: #333333;
  font-size: 15px;
  font-weight: bold;
  padding: 9px 23px;
  text-decoration: none;
  margin-top: 25px;
}

.button-show:hover {
  background: linear-gradient(to bottom, #e0a80d 5%, #ffec64 100%);
  background-color: #e0a80d;
}

.button-show:active {
  position: relative;
  top: 1px;
}

.content-text {
  flex: 5;
  padding: 30px;
  text-align: justify;
}

.content-photo {
  flex: 3;
  max-width: inherit;
  overflow: hidden;
  border-radius: 0px;
  background: #f2f2f2;
  box-shadow:  5px 5px 33px #b8b8b8,
               -5px -5px 33px #ffffff;
}

.h3-product-intro {
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 46px;
  font-family: 'Permanent Marker', cursive;
}

.margin-product-intro {
  margin-top: 50px;
  overflow: hidden;
}

.product-list {
  overflow: hidden;
}

.product-card {
  width: 300px;
  background-color: #F9FAF5;
  padding: 10px;
}

.product-card-image {
  overflow: hidden;
  border-radius: 10px;
}

.product-card-image:hover{
  cursor: pointer;
}

.product-card img {
  max-width: 100%;
  max-height: 100%;
}

.product-card p {
  margin-top: 20px;
  color: black;
  font-family: 'Bellota Text', sans-serif;
  font-size: 20px;
  font-weight: 1000;
  text-align: center;
}

.product-card h3 {
  margin-top: 20px;
  color: black;
  font-family: 'Bellota Text', sans-serif;
  font-size: 20px;
  font-weight: 1000;
  text-align: center;
}

.product-card p {
  font-size: 14px;
  margin-bottom: 10px;
}

/* zoom-rotate */
.zoom-rotate img {
  transition: transform 0.5s ease-in-out;
}

.zoom-rotate:hover img {
  transform: scale(1.5) rotate(10deg);
}


.comingSoonContainer {  
    justify-content: center;
    padding-top: 100px;
}

.comingSoon {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.comingSoon h2 {
  font-size: 30px;
}

@media (max-width: 768px) {
  .product-list {
    flex-direction: column;
    padding: 0px 0 0 0;
  }

  .shop-category {
    width: 100%;
    padding: 0 20px 20px 20px;
  }

  .currCategory {
    padding-bottom: 20px;
  }

  .shop-product {
    width: 100%;
    flex-wrap: wrap;
    padding-left: 15px;
  }

  .product-card {
    width: 250px;
    padding-top: 0;
  }
}

@media (max-width: 425px) {
  .product-list {
    padding: 0;
  }

  .shop-product {
    justify-content: center;
    padding: 0;
  }

  .product-card {
    width: 350px;
    padding-top: 0;
  }

  .product-card h3 {
    margin: 10px 0 10px 0;
  }
}





/*---------product-------------*/


/*---------product detail-------------*/
.product-detail-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
  padding-top: 50px;
}

.product-breadcrumb{
 padding-bottom: 30px;
}

.product-breadcrumb a {
  color: black;
  font-family: 'Bellota Text', sans-serif;
}

.product-detail-content{
  flex: 1;
  text-align: left;
}

.product-detail-content p {
 text-align: justify;
 color: black;
 font-weight: 300;
 font-size: 0.8rem;
 line-height: 3;
}

.product-detail-content h2 {
  font-size: 24px;
  text-align: left;
  margin-bottom: 10px;
  color: #e0a80d;
 }

 .product-detail-content img {
  width: 50px;
}

strong {
  color: black;
}

.shop {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.main-image {
  display: flex;
  justify-content: center;
}

.main-image--style {
  width: 430px;
  height: 430px;
  border: '1px solid black';
  border-radius: 10px;
  object-fit: contain;
}

.thumbnail-image--style {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid black;
  border-radius: 10px;
  object-fit: cover;
}

.thumbnail-images {
  display: flex;
  gap: 11px;
  margin-top: 15px;
  cursor: pointer;
}

/* Responsive layout for screens below 768px and smaller */
@media (max-width: 768px) {
  .container {
    max-width: 80%;
  }
  
  .product-detail-container {
    align-content: center;
  }

  .content-product {
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    border-radius: 0px;
  }

  .content-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .even {
    flex-direction: column-reverse;
  }

  .odd {
    flex-direction: column-reverse;
  }

  .content-photo {
    flex: 12;
  }

  .h3-product-intro {
    color: black;
    text-align: center;
    font-weight: bold;
    font-size: 30px;
  }

  .main-image {
    display: flex;
    justify-content: center;
  }

  .thumbnail-images {
    display: flex;
    justify-content: center;
  }

  .main-image--style {
    width: 330px;
    height: 330px;
    border: '1px solid black';
    border-radius: 10px;
  }

  .thumbnail-image--style {
    width: 150px;
    height: 150px;
    object-fit: fill;
    border: 1px solid black;
    border-radius: 10px;
  }

  .thumbnail-images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 13px;
    margin-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
  }

}

/* Responsive layout for screens below 425px and smaller */
@media (max-width: 425px) {
  .product-detail-container {
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding-top: 50px;
  }

  .margin-product-intro {
    margin-top: 20px;
  }

  .product-detail-content {
    text-align: justify;
    padding-left: 10px;
  }

  .thumbnail-images {
    padding-left: 0;
    padding-right: 0;
    gap: 10px;
  }

  .thumbnail-image--style {
    width: 135px;
    height: 135px;
  }

  .main-image--style {
    width: 250px;
    height: 250px;
    /* object-fit: cover; */
  }
}

@media (max-width: 375px) {
  .thumbnail-images {
    padding-left: 0;
    padding-right: 0;
    /* gap: 10px; */
  }

  .thumbnail-image--style {
    width: 120px;
    height: 120px;
  }

}
/*---------product detail-------------*/