.small-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  form {
    width: 400px;
    padding: 20px;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: var(--primaryColor);;
  }
  
  label {
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    color: black;
  }
  
  input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 15px;
    color: black;
  }
  
  input[type="submit"] {
    background-color: #4caf50;
    color: #fff;
    cursor: pointer;
  }
  
  input[type="submit"]:hover {
    background-color: #45a049;
  }
  