.image-grid-container {
  padding: 10px 5px 0 5px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  overflow: hidden;
}

.grid-item {
  display: flex;
  flex-direction: column;
}

.image {
  flex: 2;
  /* Takes 2/3 of the grid item height */
  overflow: hidden;
  border-radius: 5px;
}

img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.portrait-photo{
  /* Takes 1/3 of the grid item height */
  flex: 1;
  max-width: 100%;

}

.grid-item.odd {
  flex-direction: column-reverse;
}

.image-array {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  gap: 8px;
}

.image-array img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  
}