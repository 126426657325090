@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* AboutUsPage.css */
.about-us-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px 0 10px;
}

.about-us-content {
  max-width: 600px;
  text-align: center;
}

h2 {
  color: #e0a80d;
  font-family: 'Bellota Text', sans-serif;
  font-size: 23px;
  margin-bottom: 10px;
}

.about-us-content p {
  color: black;
  font-weight: 300;
  font-size: 15px;
  line-height: 2;
  /* text-align: left; */
}

strong {
  font-weight: 600;
}

.about-us-video {
  width: 540px;
  height: 310px;
}

.about-us-video iframe {
  position: static;
  width: 100%;
  height: 100%;
}

.about-us-image img {
  max-height: 700px;
  margin-bottom: 20px;
  object-fit: contain;
}


/* CSS used on AboutHome.jsx */
.product-advantages {
  display: flex;
  flex-direction: row;
  padding: 30px;
  gap: 80px;
  justify-content: center;
}

.advantage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.advantage img {
  object-fit: contain;
}

.advantage-image {
  object-fit: contain;
  width: 100px;
  height: 100px;
}

.advantage-title p {
  font-size: 16px;
  font-weight: 500;
  color: black;
  padding-top: 7px;
}

/* Responsive layout for screens 1024px and larger */
@media (min-width: 1024px) {
  .about-us-container {
    flex-direction: row;
    justify-content: center;
  }

  .about-us-intro {
    flex: 1;
    padding: 0 20px;
    text-align: right;
  }

  .about-us-image {
    max-width: 600px;
    flex: 1;
    padding: 0 20px;
  }

  .about-us-content {
    flex: 1;
    padding: 0 50px;
    text-align: right;
  }

  .second-content {
    text-align: left;
  }
}

@media (min-width: 1440px) {
  .second-content {
    margin: -40px;
    margin-top: -150px;
  }

}


/* Responsive layout for screens below 768px and smaller */
@media (max-width: 768px) {
  .about-us-video {
    width: 100%;
    height: 350px;
    align-items: center;
    padding: 10px;
    margin-top: 10px;
  }

  .product-advantages {
    flex-wrap: wrap;
    gap: 90px;
  }

  .about-us-content p {
    text-align: justify;
  }

  .about-us-content strong {
    text-align: center;
  }

  .about-us-image {
    order: 2;
  }

  .second-content {
    order: 1;
    padding-bottom: 40px;
  }
}

/* Special css for Video for Tablet Only*/
@media (max-width: 768px) and (min-width: 450px) {
  .about-us-video {
    width: 650px;
    height: 450px;
    align-self: center;
  }
}

/* Responsive layout for screens below 425 and smaller */
@media (max-width: 425px) {
  .product-advantages {
    flex-wrap: none;
    display: flex;
    flex-direction: row;
    gap: 35pxw;
    justify-content: center;
  }

  .advantage-image {
    object-fit: contain;
  }

  .advantage-title p {
    font-size: 12px;
    font-weight: 500;
  }

  .about-us-video {
    width: 100%;
    height: 250px;
  }
}

@media (max-width: 325px) {
  .advantage-image {
    object-fit: contain;
    width: 80px;
    height: 80px;
  }

}


/* Contact Us */

.contact-us-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 85px;
}

.contact-us-video {
  flex: 12;
  height: auto;
}

.contact-us-video iframe {
  position: static;
  width: 100%;
}

.contact-us-social {
  flex: 6;
  border-radius: 5px;
  box: shadow 5px;
  padding: 20px;
  text-align: center;
  justify-content: center;
}

.contact-us-social p {
  font-size: 16px;
  color: #333;
  margin: 10px 0;
}

.contact-us-social h1 {
  font-size: 35px;
}

.contact-us-social a {
  text-decoration: none;
  color: #007BFF;
  font-weight: 600;
}

.contact-us-social a:hover {
  text-decoration: underline;
}


@media (max-width: 768px) {

  .contact-us-social {
    padding: 50px 20px 30px 20px;

  }
}

/*============HOME============*/
/* Home Video CSS */
.video-caption {
  /* padding-top: 5vh;
  padding-bottom: 1vh; */
  padding: 5vh 1vh 1vh 1vh;
}

.video-caption h2 {
  font-family: 'Quicksand', sans-serif;
  font-size: 10vh;
  text-align: center;
}

/* This is only Underline / Divider for the Caption */
.video-caption h1 {
  border-top: 3px solid #e0a80d;
  margin: 4vh 35vw
}

.home-video {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px 0 20px;
}

.home-video iframe {
  width: 90vw;
  height: 75vh;
}

@media (max-width: 768px) {
  .contact-us-container {
    padding: 0;
    flex-direction: column;
  }

  .contact-us-video iframe {
    width: 100vw;
  }

  .home-video iframe {
    height: 50vh;
  }

  .video-caption h2 {
    font-size: 7vh;
  }
}

@media (max-width: 425px) {
  .contact-us-container {
    padding: 0 0 35px 0;

  }

  .contact-us-video iframe {
    width: 100vw;
    height: 200px;
  }

  .video-caption h2 {
    font-size: 3.5vh;
  }
}
